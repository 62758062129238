import styles from './faq.module.scss';
import Container from '../app/container/container';
import { useState } from 'react';
import { faqArr, faqCategories } from './faq-data';
import { useTranslation } from 'react-i18next';


function Accordion({ category, lang }) {
  const [openedAccordionItem, setOpenedAccordionItem] = useState(-1);

  const handleClickAccordion = (index) => {
    if (openedAccordionItem === index) {
      setOpenedAccordionItem(-1);
    } else {
      setOpenedAccordionItem(index);
    }
  };

  return (
    <div className={styles.accordion}>
      {faqArr.map((el, index) => el.section === category && (
        <div className={`${styles.accordionItem} ${openedAccordionItem === index ? styles.accordionItemOpen : ``}`} key={el.id}>
          <div className={styles.accordionHead} onClick={() => handleClickAccordion(index)}>
            <b className={styles.accordionLabel}>{el.locales[lang]?.question}</b>
            <button className={styles.accordionButton}>
              <span className="visually-hidden">
                {openedAccordionItem === index ? (
                  'Скрыть ответ'
                ) : (
                  'Читать ответ'
                )}
              </span>
            </button>
          </div>
          {openedAccordionItem === index && (
            <div className={styles.accordionPanel}>
              <div>{el.locales[lang].answer}</div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

function Faq() {
  const { i18n, t } = useTranslation();
  const lang = i18n?.resolvedLanguage;

  const [openedCategoryItem, setOpenedCategoryItem] = useState(`about`);

  return (
    <Container>
      <section className={styles.faq} id="faq">
        <h2 className={styles.title}>{t('faq.title1')}<span className={styles.red}>{" "}{t('faq.title2')}</span></h2>

        <div className={styles.bg}>
          <div className={styles.categories}>
            {faqCategories.map((item) => (
              <div className={styles.categoryItem} key={item.id}>
                <div className={styles.categoryHead}>
                  <button
                    onClick={() => setOpenedCategoryItem(item.category)}
                    className={`${styles.categoryButton} ${item.category === openedCategoryItem ? styles.categoryButtonActive : ``}`}
                  >
                    {item.label[lang]}
                  </button>
                </div>

                {item.category === openedCategoryItem && (
                  <div className={styles.categoryPanelMobile}>
                    <Accordion category={item.category} lang={lang} />
                  </div>
                )}

              </div>
            ))}
          </div>

          <div className={styles.categoryPanelDesktop}>
            <Accordion category={openedCategoryItem} lang={lang} />
          </div>
        </div>
      </section>
    </Container>
  );
}

export default Faq;
