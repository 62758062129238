import styles from './page-top.module.scss';
import { ReactComponent as LogoOneRun } from '../../assets/images/logo-one-run.svg';
// import { ReactComponent as LogoZabeg } from '../../assets/images/logo-zabeg.svg';
import Timer from '../timer/timer';
// import logoPsb from '../../assets/images/logo-psb.svg';
import Container from '../app/container/container';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useMediaQuery } from 'react-responsive';

function PageTop({ country }) {
  const { i18n, t } = useTranslation();
  const lang = i18n?.resolvedLanguage;

  const changeLang = (evt) => {
    const { value } = evt.target;

    i18next.changeLanguage(value);
    document.documentElement.lang = i18n.language;
  };

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <section className={`${styles.pageTop} ${country ? styles.pageTopCountry : ''}`}>
      <Container>
        <div className={styles.head}>
          {/*{country && (*/}
          {/*  <p className={styles.cityWrapper}>*/}
          {/*    Ваша страна: <Link to="/" className={styles.city}>Таджикистан</Link>*/}
          {/*  </p>*/}
          {/*)}*/}

          <div className={styles.logos}>
            <LogoOneRun className={styles.logo} width={128} height={70} />
            {/*<LogoZabeg className={styles.logoZabeg} />*/}
          </div>

          {/* <div className={styles.timerWrap}>
            <Timer />
          </div> */}

          {!country && (
            <div className={styles.langWrap}>
              <input className={`${styles.inputLang} visually-hidden`}
                type="radio"
                value="ru"
                name="language"
                id="rusLang"
                onChange={changeLang}
                checked={lang === `ru`}
              />
              <label htmlFor="rusLang" className={styles.headerLabel}>Рус</label>
              <input className={`${styles.inputLang} visually-hidden`}
                type="radio"
                value="en"
                name="language"
                id="engLang"
                onChange={changeLang}
                checked={lang === `en`}
              />
              <label htmlFor="engLang" className={styles.headerLabel}>Eng</label>
              <input className={`${styles.inputLang} visually-hidden`}
                type="radio"
                value="es"
                name="language"
                id="esLang"
                onChange={changeLang}
                checked={lang === `es`}
              />
              <label htmlFor="esLang" className={styles.headerLabel}>Es</label>
              <input className={`${styles.inputLang} visually-hidden`}
                type="radio"
                value="fr"
                name="language"
                id="frLang"
                onChange={changeLang}
                checked={lang === `fr`}
              />
              <label htmlFor="frLang" className={styles.headerLabel}>FR</label>
              <input className={`${styles.inputLang} visually-hidden`}
                type="radio"
                value="zh"
                name="language"
                id="zhLang"
                onChange={changeLang}
                checked={lang === `zh`}
              />
              <label htmlFor="zhLang" className={styles.headerLabel}>中国</label>
            </div>
          )}
        </div>

        <div className={styles.content}>
          {/*<a className={styles.partnerLink} href="https://www.psbank.ru/" target="_blank" rel="noopener noreferrer">*/}
          {/*  <span className={styles.partnerLabel}>Генеральный спонсор</span>*/}
          {/*  <img className={styles.partnerLogo} src={logoPsb} width="257" height="153" alt="Спонсор ПСБ"/>*/}
          {/*</a>*/}

          {/* <p className={styles.slogan}>Заряжай бегом {times.getShortDate(start_time)}</p> */}

          {/* <div className={styles.dateWrap}>
            <p className={styles.date}>{t("pageTop.date")}</p>

            <p className={styles.description}>
              {t("pageTop.description")}
            </p>
          </div> */}

          <p className={styles.slogan} style={{ fontSize: isMobile ? "18.5vw" : lang === "fr" ? "13vw" : lang === "es" ? "12vw" : "16vw" }}>
            {t("pageTop.mainTitlePart1")} <span className={styles.focus}>{t("pageTop.mainTitlePart2")}</span>{t("pageTop.mainTitlePart3")}
          </p>
          <p className={styles.slogan}>

          </p>
          <p className={`${styles.slogan} ${styles.focus}`}>

          </p>
          {/* <a className={styles.anchor} href="#formats">{country ? 'Принять участие' : t("pageTop.btn")}</a> */}
        </div>
      </Container>
    </section>
  );
}

export default PageTop;
