import styles from './video-player.module.scss';
import poster from '../../assets/images/onerunPoster.webp';

function VideoPlayer({ video, }) {
  return (
    <div className={styles.wrapper}>
      <video className={styles.videoFrame} poster={poster} controls="controls">
        <source src={video} type="video/mp4" />
      </video>
    </div>
  );
}

export default VideoPlayer;
